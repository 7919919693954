import './App.css';
import './App.scss';
// import CMainRouter from './components/CMainRouter';
import { Layout } from 'antd';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MainState } from './MainProvider';
import { getCategories } from './api/category/categoryApi';
import CFooter from './components/Footer/CFooter';
import { CHeader } from './components/Header/CHeader';
import { getArticlesByCategoryId } from './api/articles/articlesApi';
const LazyComponent = lazy(() => import('./components/AnimationRouter'));

function App() {

    const { typeNews, setTypeNews, footerHeight, setFooterHeight, topicContent, setTopicContent, topiclst, setTopiclst, clickedCluster, setClickedCluster } = MainState();

    // Biến lấy địa chỉ url của trang
    const location = useLocation();
    const [isShowFooter, setIsShowFooter] = useState(true);

    useEffect(() => {
        getAllTopicRequest();
    }, []);

    const getAllTopicRequest = async () => {
        await getCategories()
            .then(async (res) => {
                console.log(res.data.data);
                const lstCategory = res.data.data.map((item: any) => {
                    return {
                        id: item.categoryId,
                        name: item.name,
                    };
                });
                const tmp: any[] = [];
                for (let index = 0; index < lstCategory.length; index++) {
                    const element = lstCategory[index];
                    const req = {
                        categoryId: element.id,
                        size: 1,
                        offset: 0
                    }
                    await getArticlesByCategoryId(req)
                        .then((res) => {
                            console.log(res.data.data.items);
                            if (res.data.data.items.length > 0) {
                                tmp.push(element);
                            }
                        })
                }
                console.log(tmp);
                setTopiclst(tmp);
            });
    }

    useEffect(() => {
        let urlLink = location.pathname;
        urlLink.includes('view-more') ? setIsShowFooter(false) : setIsShowFooter(true);
    }, [location]);
    return (
        <Layout>
            <CHeader />
            <Suspense fallback={<div>Loading...</div>}>
                <LazyComponent />
                {/* <AnimationRouter /> */}
            </Suspense>
            <CFooter />
            {/* {(isShowFooter) && <CFooter />} */}

        </Layout>
    );
}

export default App;
