/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { API_URL } from '../../common/enum/enum'

export const getArticlesByClusterId = (req: any) => {
    return axios.get(`${API_URL.HOST}/articles?clusterId=${req.clusterId}&size=${req.size}&offset=${req.offset}`);
}

export const getLatestArticles = (req: any) => {
    return axios.get(`${API_URL.HOST}/articles/latest?size=${req.size}&offset=${req.offset}`);
}

export const getArticlesByArticleId = (req: any) => {
    return axios.get(`${API_URL.HOST}/articles/detail?id=${req}`);
}

export const getArticlesByCategoryId = (req: any) => {
    return axios.get(`${API_URL.HOST}/articles/byCategory/${req.categoryId}?size=${req.size}&offset=${req.offset}`);
}

export const getArticlesYoutube = (req: any) => {
    return axios.get(`${API_URL.HOST}/articles/latest?type=YOUTUBE_SHORT&size=${req.size}&offset=${req.offset}`);
}