import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { MainState } from "../../MainProvider";
import Logo from '../../images/header/logo.svg';
import "./styles.footer.scss";
// Phần footer của trang web
export default function CFooter() {

    const { typeNews, setTypeNews, footerHeight, setFooterHeight, topicContent, setTopicContent, topiclst, setTopiclst, clickedCluster, setClickedCluster } = MainState();


    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visibleFooter, setVisibleFooter] = useState(true);

    // Thay đổi trạng thái của footer khi người dùng cuộn trang
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener("resize", handleWindowResize);
        const myDiv = document.getElementById('footer-main');
        if (myDiv) {
            const height = myDiv.offsetHeight;
            setFooterHeight(height);
        }

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    return (
        <motion.div className='footer-main' id='footer-main'
            initial={{ y: 0 }}
            animate={{ y: visibleFooter ? 0 : 200, }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
        >
            <div className="contact">
                <div className='footer-logo'>
                    <img src={Logo} />
                </div>
                <div className='info-contact' style={{ fontWeight: "700" }}>
                    Trang tổng hợp tin tức bằng AI từ nhiều nguồn nhất
                </div>
            </div>
            {/* <div className="contact">
                <div className='info-contact'>
                    <div className="info">
                        Giấy phép hoạt động: Hệ thống đang trong giai đoạn thử nghiệm
                    </div>
                </div>
                <div className="info-contact">
                    <div className="info">
                        Đơn vị thiết lập: Viện Inres.AI</div>
                    <div className="info">
                        Địa chỉ Tầng 7, Tòa nhà GIM, 460 Khương Đình, Thanh Xuân, Hà Nội.
                    </div>
                </div>
            </div> */}
        </motion.div>

    );
}