/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { ArrowLeftOutlined, BellOutlined, CaretDownOutlined, LogoutOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Image, Select } from 'antd'
import { useEffect, useState } from 'react'
import "./styles.header.scss"
// import "./styles.css";
import { useNavigate } from "react-router-dom"
// import CRegisterModal from './CRegisterModal';
import { AnimatePresence, motion } from 'framer-motion'
import { MainState } from '../../MainProvider'
import Logo from '../../images/header/logo.svg'

const { Option } = Select;

// Phần header của trang web
export const CHeader = () => {

    const { typeNews, setTypeNews, footerHeight, setFooterHeight, topicContent, setTopicContent, topiclst, setTopiclst, clickedCluster, setClickedCluster } = MainState();

    const [visible, setVisible] = useState(false); // Biến thể hiện nút thu gọn menu có đang mở hay không
    const navigate = useNavigate(); // Hàm dùng để chuyển trang
    const [isClickUser, setIsClickUser] = useState(false) // Biến thể hiện nút user có đang được click hay không
    const [changeView, setChangeView] = useState<number>(1)

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visibleHeader, setVisibleHeader] = useState(true);
    const [scrollHeaderUpLenght, setScrollHeaderUpLenght] = useState(-50);
    const [lstTopic, setLstTopic] = useState<any[]>([]); // Danh sách chủ đề



    // Hàm xử lý khi scroll chuột
    useEffect(() => {
        const handleScroll = () => {
            // const currentScrollPos = window.pageYOffset;
            const { scrollTop, scrollHeight, clientHeight } = document.body;
            scrollTop > 25 ? setVisibleHeader(false) : setVisibleHeader(true)
            setPrevScrollPos(scrollTop);
        };

        document.body.addEventListener('scroll', handleScroll);
        return () => {
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    // Thay đổi trạng thái của header khi người dùng cuộn trang
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener("resize", handleWindowResize);
        if (window.innerWidth > 1920) {
            setScrollHeaderUpLenght(-45)
        }
        if (window.innerWidth <= 1920) {
            setScrollHeaderUpLenght(-95)
        }
        if (window.innerWidth <= 1440) {
            setScrollHeaderUpLenght(-85)
        }
        if (window.innerWidth <= 1280) {
            setScrollHeaderUpLenght(-80)
        }
        if (window.innerWidth <= 1000) {
            setScrollHeaderUpLenght(-70)
        }
        if (window.innerWidth <= 800) {
            setScrollHeaderUpLenght(-60)
        }
        if (window.innerWidth <= 600) {
            setScrollHeaderUpLenght(-65)
        }
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    // Kiểm tra xem đường dẫn đang là gì để set thuộc tính đã click cho header
    useEffect(() => {
        if (window.location.pathname === '/') {
            setChangeView(1)
        }
        if (window.location.pathname === '/topic') {
            setChangeView(2)
        }
        if (window.location.pathname === '/latest-news') {
            setChangeView(3)
        }
        if (window.location.pathname === '/user')
            setIsClickUser(true)
        else
            setIsClickUser(false)
    }, [window.location.pathname])

    // Lấy danh sách chủ đề
    useEffect(() => {
        setLstTopic(topiclst)
    }, [topiclst])

    // Hiển thị ra nút thu gọn menu
    const showDrawer = () => {
        setVisible(!visible);
    }

    // Hàm xử lý khi click vào nút user
    const handleClickUser = () => {
        navigate("/user") // Chuyển trang đến trang user
    }

    // Hàm xử lý khi chọn chủ đề bài viết
    const handleChangeValueSelect = (value: any) => {
        console.log(value);
        setTopicContent(value)
        // Lưu giá trị vào localStorage
        localStorage.setItem('topicContent', value);


        if (value !== undefined) {
            navigate('/topic')
        }
    }

    // Hàm xử lý khi click vào logo
    const onClickLogo = () => {
        navigate('/');
        window.location.reload();
    }

    return (
        <>
            {/* {!visible && */}
            <motion.div className={'main-header ' + (visibleHeader ? '' : 'hide')}
                initial={{ y: 0 }}
                animate={{ y: visibleHeader ? 0 : scrollHeaderUpLenght, }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            >
                <motion.div className='header-info'
                // initial={{ y: 0 }}
                // animate={{ y: visibleHeader ? 0 : -100, }}
                // transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                    <motion.div className="header-left"

                    >
                        {/* <Link to={'/'}> */}
                        <div className='header-logo' onClick={onClickLogo}>
                            <Image src={Logo} preview={false} />
                        </div>
                        {/* </Link> */}
                    </motion.div>
                    <motion.div className={`header-content-input `}>
                        {/* <Input
                            className='search-input'
                            placeholder='Tìm kiếm bài viết, chủ đề, nguồn'
                        />
                    <SearchOutlined /> */}
                        <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined />}
                            onChange={handleChangeValueSelect}
                            placeholder="Tìm kiếm bài viết, chủ đề, nguồn"
                        >
                            {lstTopic?.map((index, item) => (
                                <Option key={index.id} value={index.id}>{index.name}</Option>
                            ))}
                        </Select>
                        {/* <SearchOutlined className='icon-search' /> */}
                    </motion.div>
                    <motion.div className="header-right">
                        <div className='user-infor'>
                            <div className='icon-group'>
                                <div><BellOutlined /></div>
                                <div ><SettingOutlined className={isClickUser ? 'active' : ''} /></div>
                                <div><LogoutOutlined /></div>
                            </div>
                        </div>
                        <Button className={`menubtn`} shape="circle" icon={<SearchOutlined />} onClick={showDrawer} ></Button>
                    </motion.div>
                </motion.div>
                <hr></hr>
                <motion.div className='header-type-news'>
                    <div onClick={() => {
                        setChangeView(1)
                        navigate('/')
                    }}
                        className={changeView === 1 ? 'content active' : 'content'}>
                        Trang chủ
                    </div>
                    <div
                        onClick={() => {
                            setChangeView(3)
                            navigate('/latest-news')
                        }}
                        className={changeView === 3 ? 'content active' : 'content'}>
                        Mới nhất
                    </div>
                    {/* <div
                        onClick={() => {
                            setChangeView(2)
                            navigate('/topic')
                        }}
                        className={changeView === 2 ? 'content active' : 'content'}>
                        Hóng Trend
                    </div> */}
                </motion.div>
            </motion.div >
            {/* } */}
            <AnimatePresence>
                {
                    visible &&
                    <motion.div
                        className='search-active'
                        initial={{ x: 600 }}
                        animate={{ x: 0 }}
                        exit={{ x: 600, transition: { duration: 0.5 } }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}>
                        <ArrowLeftOutlined onClick={showDrawer} />
                        <div className='search-input-content'>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                suffixIcon={<CaretDownOutlined />}
                                onChange={handleChangeValueSelect}
                                placeholder="Tìm kiếm bài viết, chủ đề, nguồn"
                            >
                                {lstTopic.map((index) => (
                                    <Option value={index.id}>{index.name}</Option>
                                ))}
                            </Select>
                        </div>
                        {/* <SearchOutlined className='icon-search' /> */}
                    </motion.div>}
            </AnimatePresence>
        </>
    )
}
