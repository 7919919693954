import React, { useState, createContext, useContext, ReactNode } from 'react';
import { CONST } from './common/enum/enum';
import { ICluster } from './common/tin-gpt/define-cluster';

const MainContext = createContext();
function MainProvider({ children }) {
    const [typeNews, setTypeNews] = useState(1);
    const [footerHeight, setFooterHeight] = useState(0);
    const [topicContent, setTopicContent] = useState(CONST.HOT_TOPIC_ID);
    const [topiclst, setTopiclst] = useState([]);
    const [clickedCluster, setClickedCluster] = useState();

    return (
        <MainContext.Provider value={{
            typeNews,
            footerHeight,
            topicContent,
            topiclst,
            clickedCluster,
            setTypeNews,
            setFooterHeight,
            setTopicContent,
            setTopiclst,
            setClickedCluster,
        }}>
            {children}
        </MainContext.Provider>
    );
}
export const MainState = () => {
    return useContext(MainContext);
};
export default MainProvider;
